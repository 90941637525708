
<script>
import { Bar, mixins } from 'vue-chartjs';
import annotationPlugin from 'chartjs-plugin-annotation';
const { reactiveProp } = mixins;

export default {
  name: 'bar',
  extends: Bar,
  props:['options', 'chartData', 'onClick'],
  mixins: [ reactiveProp ],
  mounted() {
    this.options.onClick = this.ClickHandler;
    this.addPlugin(annotationPlugin);
    this.renderChart(
      this.chartData,
      this.options
    )
  },
  watch: {
     options () {
        this.options.onClick = this.ClickHandler;
        this.renderChart(this.chartData, this.options);
     }
  },
  methods: {
    ClickHandler: function(event) {
      const c = this._data._chart;
      const datapoint = c.getElementAtEvent(event)[0];
      const indexBar = datapoint._index;
      const indexSegment = datapoint._datasetIndex;

      const value = this.chartData.datasets[indexSegment].data[indexBar];
      const label = this.chartData.labels[indexBar];

      if (typeof this.onClick === 'function') this.onClick({ value, label })
    }
  }
};
</script>
