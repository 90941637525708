
<script>
import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  name: 'stackedBar',
  extends: Bar,
  props:['options', 'chartData'],
  mixins: [ reactiveProp ],
  mounted() {
    this.renderChart(
      this.chartData,
      {
          responsive: true, 
          maintainAspectRatio: false,
          scales: {
              xAxes: [{
                  stacked: true,
                  categoryPercentage: 0.5,
                  barPercentage: 1,
                  ticks: {
                    autoSkip: false
                  }
              }],
              yAxes: [{
                  stacked: true
              }]
          }
      }
    )
  }
};
</script>
