<template>
  <div class="mx-8 my-8">

    <h1 class="text-3xl mb-5">Gráficos</h1>

    <div class="grid grid-cols-12 gap-6 mb-3">
      <div class="col-span-12 xl:col-span-4 mb-1 mt-1">
          <label for="obra" class="block text-sm font-medium">Obra</label>
          <select @change="getCharts" v-model="obra" id="obra" name="obra" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            <option v-for="ob in obras" :key="ob._id" :value="ob._id" ><span v-if="$store.state.user.root">{{ ob.empresa.nome }} - </span>{{ ob.nome }}</option>
          </select>
      </div>

      <div class="col-span-12 md:col-span-4 mb-1 mt-1">
        <label for="data" class="block text-sm font-medium">Data Inicial</label>
        <datepicker @closed="getCharts" v-model="dataInicial" name="dataInicial" id="dataInicial" :language="ptBR" input-class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
      </div>
      <div class="col-span-12 md:col-span-4 mb-1 mt-1">
        <label for="data" class="block text-sm font-medium">Data Final</label>
        <datepicker @closed="getCharts" v-model="dataFinal" name="dataFinal" id="dataFinal" :language="ptBR" input-class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
      </div>
    </div>
   
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <h4 class="font-bold text-base mb-2">Gráficos</h4>
          <div>
            <hr>
            <br>
            <p class="font-bold">Luz</p>
            <Bar :chart-data="chartBar" :options="optionsLuz" :onClick="(ev) => graficoAguaLuzOnClick(ev,'Luz')"></Bar>
            <hr>
            <br>
            <p class="font-bold">Água</p>
            <Bar :chart-data="chartBarAgua" :options="optionsAgua" :onClick="(ev) => graficoAguaLuzOnClick(ev,'Água')"></Bar>
            <hr>
            <br>
            <p class="font-bold pb-3">Observações</p>
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Tipo
                  </th>
                  <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Data
                  </th>
                   <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Consumo dia
                  </th>
                  <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Consumo noite
                  </th>
                  <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Observação
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="item in observacoes" :key="item._id">
                  <td class="px-3 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.tipo === 'Água' ? 'Água' : 'Luz'}}
                      </div>
                    </div>
                  </td>
                  <td class="px-3 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.data | moment("DD/MM/YYYY") }}
                      </div>
                    </div>
                  </td>
                  <td class="px-3 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500" :class="item.total < 0 ? 'text-red-500 font-bold' : ''">
                        {{item.total ? `${item.tipo === 'Água' ? `${(item.total).toFixed(2)} m³` : `${(item.total).toFixed(2)} kwh` }` : '0'}}
                      </div>
                    </div>
                  </td>
                  <td class="px-3 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500" :class="item.totalNoite < 0 ? 'text-red-500 font-bold' : ''">
                        {{item.totalNoite ? `${item.tipo === 'Água' ? `${(item.totalNoite).toFixed(2)} m³` : `${(item.totalNoite).toFixed(2)} kwh` }` : '0'}}
                      </div>
                    </div>
                  </td>
                  <td class="px-3 py-4">
                    <div class="flex items-center">
                      <div class="text-sm text-gray-500">
                        {{item.obs}}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr>
            <br>
            <p class="font-bold">Resíduos</p>
            <div class="grid grid-cols-12 mt-3 mb-2">
              <div class="col-span-12 xl:col-span-3 mt-1 mb-1">
                <Pie :chart-data="chartPieResiduos"></Pie>
              </div>
              <div class="col-span-12 xl:col-span-9 mt-1 mb-1">
                <StackedBar :chart-data="chartStacked"></StackedBar>
              </div>
            </div>
            <p class="font-bold">Funcionários</p>
            <Bar :chart-data="chartBarFunc" :options="optionsFunc" :onClick="graficoFuncOnClick"></Bar>
            <hr>
            <br>
            <p class="font-bold">Água x Funcionários</p>
            <Bar :chart-data="chartBarAguaxFuncionario" :options="optionsFunc"></Bar>
            <hr>
            <br>
            <p class="font-bold">Luz x Funcionários</p>
            <Bar :chart-data="chartBarLuzxFuncionario" :options="optionsFunc"></Bar>
            <hr>
            <br>
            <p class="font-bold">Resíduos x Funcionários</p>
            <StackedBar :chart-data="chartBarResiduosxFuncionario" :options="optionsResiduosxFuncionario"></StackedBar>
            <hr>
            <br>
          </div>
        </div>
      </div>
    </div>


    <modal name="modalAtualizarValor" :adaptive="true" :height="'auto'">
      <div class="px-5 py-5">
        <h2 class="text-xl font-bold mb-2">Atualizar valor</h2>
        <div class="grid grid-cols-12 gap-6">
            <div class="col-span-12 md:col-span-4">
                <label for="leitura" class="block text-sm font-medium">Leitura Inicial</label>
                <input v-model="itemModalAguaLuz.leitura" type="number" name="leitura" id="leitura" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>
            <div class="col-span-12 md:col-span-4">
                <label for="leituraFinal" class="block text-sm font-medium">Leitura Final</label>
                <input v-model="itemModalAguaLuz.leituraFinal" type="number" name="leituraFinal" id="leituraFinal" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>
            <div class="col-span-12 md:col-span-4">
                <label for="totalNoite" class="block text-sm font-medium">Consumo noite</label>
                <input v-model="itemModalAguaLuz.totalNoite" type="number" name="leituraFinal" id="totalNoite" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>
            <div class="col-span-12 md:col-span-12">
                <label for="obs" class="block text-sm font-medium">Observação</label>
                <textarea v-model="itemModalAguaLuz.obs" name="obs" id="obs" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"></textarea>
            </div>
        </div>
        <div class="grid grid-cols-2 grap-6">
            <div class="px-5 py-3 text-left sm:px-6">
                <button @click="$modal.hide('modalAtualizarValor')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Voltar
                </button>
            </div>
            <div class="px-5 py-3 text-right sm:px-6">
                <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400">
                    Salvar
                </button>
            </div>
        </div>
      </div>
    </modal>

    <modal name="modalAtualizarFunc" :adaptive="true" :height="'auto'">
      <div class="px-5 py-5">
        <h2 class="text-xl font-bold mb-2">Atualizar valor</h2>
        <div class="grid grid-cols-12 gap-6">
            <div class="col-span-4">
                  <label for="mes" class="block text-sm font-medium">Mês da leitura</label>
                  <select v-model="itemModalFunc.mes" id="mes" name="mes" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option :value="1">Janeiro</option>
                    <option :value="2">Fevereiro</option>
                    <option :value="3">Março</option>
                    <option :value="4">Abril</option>
                    <option :value="5">Maio</option>
                    <option :value="6">Junho</option>
                    <option :value="7">Julho</option>
                    <option :value="8">Agosto</option>
                    <option :value="9">Setembro</option>
                    <option :value="10">Outubro</option>
                    <option :value="11">Novembro</option>
                    <option :value="12">Dezembro</option>
                  </select>
            </div>
            <div class="col-span-4">
                  <label for="ano" class="block text-sm font-medium">Ano da leitura</label>
                  <select v-model="itemModalFunc.ano" id="ano" name="ano" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option v-for="idx in 6" :key="idx - 6" :value="$moment().subtract(6 - idx, 'years').format('YYYY')">{{ $moment().subtract(6 - idx, 'years').format('YYYY') }}</option>
                    <option v-for="idx in 5" :key="idx" :value="$moment().add(idx, 'years').format('YYYY')">{{ $moment().add(idx, 'years').format('YYYY') }}</option>
                  </select>
            </div>
            <div class="col-span-12 md:col-span-4">
                <label for="valorAgua" class="block text-sm font-medium">Quantidade</label>
                <input v-model="itemModalFunc.qtd" type="number" name="valorAgua" id="valorAgua" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>
        </div>
        <div class="grid grid-cols-2 gap-6">
            <div class=" py-3 text-left">
                <button @click="$modal.hide('modalAtualizarFunc')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Voltar
                </button>
            </div>
            <div class=" py-3 text-right">
                <button @click="saveFunc" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400">
                    Salvar
                </button>
            </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import Bar from './Bar';
import StackedBar from './StackedBar';
import Pie from './Pie';

export default {
    components: {
        Datepicker,
        Bar,
        StackedBar,
        Pie
    },
    data() {
        return {
            ptBR: ptBR,
            route: 'graficos',
            dataInicial: new Date(moment().startOf('month')),
            dataFinal: new Date(moment().endOf('day')),
            observacoes: [],
            obras: [],
            obra: null,
            optionsAgua: {
              drawTime: 'afterDatasetsDraw',
              annotation: {
                annotations: [{
                  type: 'line',
                  mode: 'horizontal',
                  scaleID: 'y-axis-0',
                  value:0,
                  borderColor: '#000000',
                  borderWidth: 4,
                  label: {
                    enabled: true,
                    content: `Média`
                  }
                }]
              },
              responsive: true, 
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'top',
                },
              },
              scales: {
                  yAxes: [{
                      ticks: {
                          beginAtZero: true,
                          min: 0,
                      }
                  }],
                  xAxes: [{
                      ticks: {
                          autoSkip: false,
                      }
                  }]
              }
            },
            optionsLuz: {
              drawTime: 'afterDatasetsDraw',
              annotation: {
                annotations: [{
                  type: 'line',
                  mode: 'horizontal',
                  scaleID: 'y-axis-0',
                  value:0,
                  borderColor: '#000000',
                  borderWidth: 4,
                  label: {
                    enabled: true,
                    content: `Média`
                  }
                }]
              },
              responsive: true, 
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'top',
                },
              },
              scales: {
                  yAxes: [{
                      ticks: {
                          beginAtZero: true
                      }
                  }]
              },
            },
            optionsFunc: {
                responsive: true, 
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            },
            optionsResiduosxFuncionario: {
                responsive: true, 
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'top',
                  },
                },
                scales: {
                   xAxes: [{
                        stacked: true
                    }],
                    yAxes: [{
                        stacked: true
                    }]
                }
            },
            chartBar: {
              labels: ['carregando...', 'carregando...', 'carregando...'],
              datasets: [
                {
                  label: 'Luz (kwh)',
                  data: [0, 0, 0],
                  borderColor: '#FECC00',
                  backgroundColor: '#FECC00',
                },
              ]
            },
            chartBarAgua: {
              labels: ['carregando...', 'carregando...', 'carregando...'],
              datasets: [
                {
                  label: 'Água (L)',
                  data: [0, 0, 0],
                  borderColor: '#00A0AC',
                  backgroundColor: '#00A0AC',
                },
              ]
            },
            chartBarFunc: {
              labels: ['carregando...', 'carregando...', 'carregando...'],
              datasets: [
                {
                  label: 'Funcionários',
                  data: [0, 0, 0],
                  borderColor: '#00D99F',
                  backgroundColor: '#00D99F',
                },
              ]
            },
            chartBarAguaxFuncionario: {
              labels: ['carregando...', 'carregando...', 'carregando...'],
              datasets: [
                {
                  label: 'Funcionários',
                  data: [0, 0, 0],
                  borderColor: '#00D99F',
                  backgroundColor: '#00D99F',
                },
              ]
            },
            chartBarLuzxFuncionario: {
              labels: ['carregando...', 'carregando...', 'carregando...'],
              datasets: [
                {
                  label: 'Funcionários',
                  data: [0, 0, 0],
                  borderColor: '#00D99F',
                  backgroundColor: '#00D99F',
                },
              ]
            },
            chartBarResiduosxFuncionario: {
              labels: ['carregando...', 'carregando...', 'carregando...'],
              datasets: [
                {
                  label: 'Funcionários',
                  data: [0, 0, 0],
                  borderColor: '#00D99F',
                  backgroundColor: '#00D99F',
                },
              ]
            },
            chartPieColors: ['#607D8B', '#E53935', '#FFCC80', '#FFFF00', '#A7FFEB', '#6D4C41', '#FFCDD2', '#880E4F'],
            chartPieResiduos: {
              labels: ['CARREGANDO...', 'CARREGANDO...', 'CARREGANDO...', 'CARREGANDO...'],
              datasets: [
                {
                  backgroundColor: ['#607D8B', '#E53935', '#FFCC80', '#FFFF00', '#A7FFEB', '#6D4C41', '#FFCDD2', '#880E4F'],
                  data: [0, 0, 0, 0, 0, 0, 0, 0]
                }
              ]
            },
            chartStacked:  {
                labels: ['CARREGANDO...', 'CARREGANDO...', 'CARREGANDO...', 'CARREGANDO...'],
                datasets: [
                    {
                        label: 'Entulho',
                        backgroundColor: '#607D8B',
                        data: [0, 0, 0, 0]
                    },
                    {
                        label: 'Gesso',
                        backgroundColor: '#E53935',
                        data: [0, 0, 0, 0]
                    },
                    {
                        label: 'Papel/plástico',
                        backgroundColor: '#FFCC80',
                        data: [0, 0, 0, 0]
                    },
                    {
                        label: 'Papelão',
                        backgroundColor: '#FFFF00',
                        data: [0, 0, 0, 0]
                    },
                    {
                        label: 'Madeira',
                        backgroundColor: '#A7FFEB',
                        data: [0, 0, 0, 0]
                    },
                    {
                        label: 'Tintas',
                        backgroundColor: '#6D4C41',
                        data: [0, 0, 0, 0]
                    },
                    {
                        label: 'Contaminado',
                        backgroundColor: '#FFCDD2',
                        data: [0, 0, 0, 0]
                    },
                    {
                        label: 'Metal',
                        backgroundColor: '#880E4F',
                        data: [0, 0, 0, 0]
                    }
                ]
            },
            itemModalAguaLuz: {
              ativo: false,
              data: "",
              leitura: 0,
              leituraFinal: 0,
              obra: "",
              tipo: "",
              total: 0,
              totalNoite: 0,
              usuario: "",
              __v: 0,
              _id: "",
              obs: "",
            },
            itemModalFunc: {
              ano: 0,
              ativo: false,
              mes: 0,
              obra: "",
              qtd: 0,
              usuario: "",
              __v: 0,
              _id: "",
            }
        }
    },
    methods: {
        async graficoAguaLuzOnClick(ev, tipo) {
          const datainicial = moment(ev.label, 'DD/MM/YYYY').startOf('day').toISOString();
          const datafinal = moment(ev.label, 'DD/MM/YYYY').endOf('day').toISOString();

          const filtro = { obra: this.obra, tipo, total: ev.value, data: { $gte: datainicial, $lte: datafinal} };

          const reqItem = await this.$http.post('/v1/agualuz/findone', filtro);

          if (reqItem.data) {
            this.itemModalAguaLuz = reqItem.data;
            this.$modal.show('modalAtualizarValor');
          }
        },
        async graficoFuncOnClick(ev) {
          const mes = moment(ev.label, 'M/YYYY').startOf('day').format('MM');
          const ano = moment(ev.label, 'M/YYYY').startOf('day').format('YYYY');

          const filtro = { obra: this.obra, qtd: ev.value, mes, ano };

          const reqItem = await this.$http.post('/v1/qtdfunc/findone', filtro);

          if (reqItem.data) {
            this.itemModalFunc = reqItem.data;
            this.$modal.show('modalAtualizarFunc');
          }
        },
        async save() {
            if (!this.itemModalAguaLuz._id) return;

            const req = await this.$http.put(`/v1/agualuz`, this.itemModalAguaLuz);

            if (req.data.success) {
                this.$modal.hide('modalAtualizarValor')
                this.$vToastify.success("Atualizado com sucesso!");
                this.getCharts();
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        async saveFunc() {
            const req = await this.$http.put(`/v1/qtdfunc`, this.itemModalFunc);

            if (req.data.success) {
                this.$modal.hide('modalAtualizarFunc')
                this.$vToastify.success("Atualizado com sucesso!");
                this.getCharts();
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        async getCharts() {
            if (this.obra) {
              const reqobservacoes = await this.$http.post('/v1/agualuz/observacoes', { obra: this.obra, dataInicial: this.dataInicial, dataFinal: this.dataFinal });
              this.observacoes = reqobservacoes.data.data;

              const reqgraficos = await this.$http.post('/v1/agualuz/graficos', { obra: this.obra, dataInicial: this.dataInicial, dataFinal: this.dataFinal });
              
              this.chartBar = {
                labels: Object.keys(reqgraficos.data.luz.dias),
                datasets: [
                  {
                    label: 'Luz (kwh)',
                    data: Object.values(reqgraficos.data.luz.dias).map(a => a.total),
                    borderColor: '#FECC00',
                    backgroundColor: '#FECC00',
                  },
                ]
              };

              this.optionsLuz = reqgraficos.data.luz.options;

              this.chartBarAgua = {
                  labels: Object.keys(reqgraficos.data.aguas.dias),
                  datasets: [
                    {
                      label: 'Água (m³)',
                      data: Object.values(reqgraficos.data.aguas.dias).map(a => a.total),
                      borderColor: '#00A1AD',
                      backgroundColor: '#00A1AD',
                    },
                  ]
              };

              this.optionsAgua = reqgraficos.data.aguas.options;

              this.chartBarFunc = {
                labels: Object.values(reqgraficos.data.func).map(a => `${a.mes}/${a.ano}`),
                datasets: [
                  {
                    label: 'Funcionários',
                    data: Object.values(reqgraficos.data.func).map(a => a.qtd),
                    borderColor: '#00D99F',
                    backgroundColor: '#00D99F',
                  },
                ]
              };

              this.chartBarAguaxFuncionario = {
                labels: Object.keys(reqgraficos.data.aguas.dias),
                  datasets: [
                    {
                      label: 'Água (m³)',
                      data: Object.values(reqgraficos.data.aguas.dias).map(a => a.total),
                      borderColor: '#00A1AD',
                      backgroundColor: '#00A1AD',
                    },
                    {
                      label: 'Funcionários mês',
                      data: Object.values(reqgraficos.data.aguas.dias).map(a => a.func),
                      borderColor: '#00D99F',
                      backgroundColor: '#00D99F',
                    }
                  ]
              }

              this.chartBarLuzxFuncionario = {
                labels: Object.keys(reqgraficos.data.aguas.dias),
                  datasets: [
                    {
                      label: 'Luz (kwh)',
                      data: Object.values(reqgraficos.data.luz.dias).map(a => a.total),
                      borderColor: '#FECC00',
                      backgroundColor: '#FECC00',
                    },
                    {
                      label: 'Funcionários mês',
                      data: Object.values(reqgraficos.data.aguas.dias).map(a => a.func),
                      borderColor: '#00D99F',
                      backgroundColor: '#00D99F',
                    }
                  ]
              }

              const pie = reqgraficos.data.pieResiduos;

              this.chartPieResiduos = {
                labels: pie.labels,
                datasets: [
                  {
                    backgroundColor: this.chartPieColors,
                    data: pie.data
                  }
                ]
              };

              const stacked = reqgraficos.data.stacked;

              this.chartBarResiduosxFuncionario = {
                labels: stacked.arrDatas,
                datasets: [
                      {
                          label: 'Funcionários mês',
                          backgroundColor: '#00D99F',
                          data: stacked.residuosByName['Funcionarios']
                      },
                      {
                          label: 'Entulho',
                          backgroundColor: '#607D8B',
                          data: stacked.residuosByName['Entulho']
                      },
                      {
                          label: 'Gesso',
                          backgroundColor: '#E53935',
                          data: stacked.residuosByName['Gesso']
                      },
                      {
                          label: 'Papel/plástico',
                          backgroundColor: '#FFCC80',
                          data: stacked.residuosByName['Papel/plástico']
                      },
                      {
                          label: 'Papelão',
                          backgroundColor: '#FFFF00',
                          data: stacked.residuosByName['Papelão']
                      },
                      {
                          label: 'Madeira',
                          backgroundColor: '#A7FFEB',
                          data: stacked.residuosByName['Madeira']
                      },
                      {
                          label: 'Tintas',
                          backgroundColor: '#6D4C41',
                          data: stacked.residuosByName['Tintas']
                      },
                      {
                          label: 'Contaminado',
                          backgroundColor: '#FFCDD2',
                          data: stacked.residuosByName['Contaminado']
                      },
                      {
                          label: 'Metal',
                          backgroundColor: '#880E4F',
                          data: stacked.residuosByName['Metal']
                      },
                  ]
              }

              this.chartStacked = {
                  labels: stacked.arrDatas,
                  datasets: [
                      {
                          label: 'Entulho',
                          backgroundColor: '#607D8B',
                          data: stacked.residuosByName['Entulho']
                      },
                      {
                          label: 'Gesso',
                          backgroundColor: '#E53935',
                          data: stacked.residuosByName['Gesso']
                      },
                      {
                          label: 'Papel/plástico',
                          backgroundColor: '#FFCC80',
                          data: stacked.residuosByName['Papel/plástico']
                      },
                      {
                          label: 'Papelão',
                          backgroundColor: '#FFFF00',
                          data: stacked.residuosByName['Papelão']
                      },
                      {
                          label: 'Madeira',
                          backgroundColor: '#A7FFEB',
                          data: stacked.residuosByName['Madeira']
                      },
                      {
                          label: 'Tintas',
                          backgroundColor: '#6D4C41',
                          data: stacked.residuosByName['Tintas']
                      },
                      {
                          label: 'Contaminado',
                          backgroundColor: '#FFCDD2',
                          data: stacked.residuosByName['Contaminado']
                      },
                      {
                          label: 'Metal',
                          backgroundColor: '#880E4F',
                          data: stacked.residuosByName['Metal']
                      },
                  ]
              }

            }
        },
        async start() {
          const reqobras = await this.$http.post('/v1/obras/list');
          this.obras = reqobras.data.data;
          if (this.obras.length) {
            this.obra = this.obras[0]._id;
          }

          this.getCharts();
        },
    },
    async beforeMount() {
      console.log(this.residuos);
        this.start();
    },
}
</script>