
<script>
import { Pie, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  name: 'pie',
  extends: Pie,
  props:['options'],
  mixins: [reactiveProp],
  mounted() {
    this.renderChart(
      this.chartData, 
      {
        responsive: true,
        maintainAspectRatio: true,
      }
    );
  }
};
</script>

